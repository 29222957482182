var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-6"},[_c('v-sheet',{staticClass:"d-flex",attrs:{"tile":"","height":"60"}},[_c('h2',[_vm._v("お知らせ管理")]),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.add}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" お知らせを追加 ")],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.notification,"sort-by":"name","items-per-page":15,"loading":_vm.loading,"loading-text":"読み込み中です","no-data-text":"対象のデータがありません"},scopedSlots:_vm._u([{key:"item.important",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.important ? '○' : '')+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('p',{staticClass:"mb-0 text--secondary v-messages",staticStyle:{"line-height":"1.5"}},[_vm._v(" ("+_vm._s(item.nameKana)+") ")])]}},{key:"item.shops",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.shopIdToShopName(item.shops))+" ")]}},{key:"item.weekdayShift",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toAbbreviate(item.weekdayShift))+" ")]}},{key:"item.holidayShift",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toAbbreviate(item.holidayShift))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status ? '有効' : '無効')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-pencil")]),_vm._v(" 編集 ")],1)]}}])}),_c('notification-dialog',{attrs:{"action":_vm.action,"selectNotification":_vm.selectNotification},on:{"reload":_vm.reload},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }