<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="640">
    <v-card>
      <v-card-title>
        <span class='text-h5'>{{ actionName }}</span>
      </v-card-title>
      <v-card-text class='pb-0'>
        <v-container
          id="scroll-target"
          style="max-height: 800px"
          class="overflow-y-auto"
        >
          <v-form ref='form' v-model='valid' lazy-validation :disabled='loading'>
          <v-row>
            <v-col cols='12' class='py-0'>
              <v-checkbox
                v-model='notification.important'
                label='重要なお知らせとして登録する'
                class='text--primary v-messages'
                hide-details='false'
              ></v-checkbox>
              <p
                class='text--secondary v-messages'
                style='line-height: 1.5;'
              >
                お知らせが会員の予約申し込み画面に表示されます。
              </p>
            </v-col>
            <v-col cols='12' class='py-0'>
              <p class='mb-0 text--primary'>
                タイトル
                <span class='required-label'>
                  必須
                </span>
              </p>
              <v-text-field
                v-model='notification.title'
                outlined
                dense
                validate-on-blur
                :rules='[formRules.required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12' class='py-0'>
              <p class='mb-0 text--primary'>
                公開期間
                <span class='required-label'>
                  必須
                </span>
              </p>
              <v-row>
                <v-col cols='12' class='d-flex justify-space-between pb-0'>
                  <Datepicker :selectDay='notification.startDate' @input='notification.startDate = $event'></Datepicker>
                  <p class='mx-2 mt-2'>~</p>
                  <Datepicker :selectDay='notification.endDate' @input='notification.endDate = $event'></Datepicker>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols='12' class='py-0'>
              <p class='mb-0 text--primary'>
                お知らせ詳細
                <span class='required-label'>
                  必須
                </span>
              </p>
              <v-textarea
                v-model='notification.detail'
                outlined
                dense
                validate-on-blur
                :rules='[formRules.required]'
              ></v-textarea>
            </v-col>
            <v-col cols='12' class='py-0'>
              <p class='mb-0 text--primary'>
                掲載店舗
                <span class='required-label'>
                  必須
                </span>
              </p>
              <p
                class='mb-0 text--secondary v-messages'
                style='line-height: 1.5;'
              >
                複数選択可能です。
              </p>
              <v-select
                v-model='notification.shops'
                :items=shops
                item-text="name"
                item-value="id"
                :menu-props='{ top: true, offsetY: true }'
                outlined
                chips
                deletable-chips
                multiple
                required
                :rules='[formRules.requiredArray]'
              ></v-select>
            </v-col>
          </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color='grey darken-1' dark outlined :disabled='loading' @click='close'>
          <v-icon class='pr-1'>mdi-close</v-icon>
          閉じる
        </v-btn>
        <v-btn color='blue darken-2' dark outlined @click='save' :loading='loading'>
          <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import util from '../util';
import Datepicker from './Datepicker';

export default {
  name: 'notification-dialog',
  components: {
    Datepicker,
  },
  props: {
    selectNotification: {
      type: Object,
      default() {
        return {};
      },
    },
    action: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    notification: {
      important: false,
      title: '',
      startDate: '',
      endDate: '',
      detail: '',
      shops: [],
    },
    shops: [],
    shopNameList: [],
    editedIndex: -1,
    loading: false,
    valid: true,
    shiftRangeItems: [],
    showPassword: false,
    // 入力規則
    formRules: {
      required: value => !!value || '必ず入力してください。',
      requiredArray: value => (value || '').length > 0 || '1つ以上選択してください。',
      limit_length: value => (value || '').length <= 0 || '20文字以内で入力してください。',
      password: value => /^(?=.*\d)((?=.*[a-z])|(?=.*[A-Z]))[0-9a-zA-Z]{8,16}$/.test(value) || '半角数字と半角英字の2種類を含む8文字以上16文字以内で入力してください。',
      email: value => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || 'メールアドレスの形式が正しくありません。',
      tel: value => /^(0\d{1,4}-\d{1,4}-\d{3,4})?$/.test(value) || '電話番号の形式が正しくありません。',
      kana: value => /^[ア-ン゛゜ァ-ォャ-ョー「」、]+$/.test(value) || '全角カタカナで入力してください。',
    },
  }),
  computed: {
    actionName() {
      return this.action === 'add' ? '追加' : '編集';
    },
  },
  watch: {
    async dialog() {
      this.$emit('input', this.dialog);
    },
    async value() {
      if (this.value) {
        this.notification = Object.assign({}, this.notification, this.selectNotification);
        await this.reload();
        this.shiftRangeItems = util.timeList(0, 23);
      } else {
        this.notification = {
          important: false,
          title: '',
          startDate: '',
          endDate: '',
          detail: '',
          shops: [],
        };
      }
      this.dialog = this.value;
    },
  },
  methods: {
    async reload() {
      this.shops = await util.callCloudFunction('getShop');
      this.shopNameList = this.shops.map((obj) => obj.name);
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.selectNotification = {};
        this.editedIndex = -1;
        this.$refs.form.resetValidation();
      });
    },
    async save() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.$store.commit('snackbar/open', { text: '入力内容に誤りがあります。', color: 'error' });
        this.loading = false;
        return;
      }
      if (this.action === 'add') {
        await util.callCloudFunction('addNotification', this.notification).then(() => {
          this.$store.commit('snackbar/open', { text: 'お知らせは正常に登録されました。', color: 'success' });
          this.$emit('reload');
          this.dialog = false;
        }).catch((res) => {
          const msg = !res ? 'お知らせの登録に失敗しました。' : res;
          this.$store.commit('snackbar/open', { text: msg, color: 'error' });
        });
      } else {
        await util.callCloudFunction('editNotification', this.notification).then(() => {
          this.$store.commit('snackbar/open', { text: 'お知らせは正常に更新されました。', color: 'success' });
          this.$emit('reload');
          this.dialog = false;
        }).catch(() => {
          this.$store.commit('snackbar/open', { text: 'お知らせの更新に失敗しました。', color: 'error' });
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.v-messages__message {
  line-height: 13px !important;
}
.v-label {
  font-size: 14px !important;
}
</style>
