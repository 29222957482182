<template>
  <div class='pa-6'>
    <v-sheet tile height='60' class='d-flex'>
      <h2>お知らせ管理</h2>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        @click="add"
      >
        <v-icon>mdi-plus</v-icon>
        お知らせを追加
      </v-btn>
    </v-sheet>
    <v-data-table
      :headers='headers'
      :items='notification'
      sort-by='name'
      class='elevation-1'
      :items-per-page='15'
      :loading='loading'
      loading-text='読み込み中です'
      no-data-text='対象のデータがありません'
    >
      <template v-slot:item.important='{ item }'>
      {{ item.important ? '○' : '' }}
      </template>
      <template v-slot:item.name='{ item }'>
      {{ item.name }}
        <p
          class='mb-0 text--secondary v-messages'
          style='line-height: 1.5;'
        >
        ({{ item.nameKana }})
        </p>
      </template>
      <template v-slot:item.shops='{ item }'>
      {{ shopIdToShopName(item.shops) }}
      </template>
      <template v-slot:item.weekdayShift='{ item }'>
      {{ toAbbreviate(item.weekdayShift) }}
      </template>
      <template v-slot:item.holidayShift='{ item }'>
      {{ toAbbreviate(item.holidayShift) }}
      </template>
      <template v-slot:item.status='{ item }'>
      {{ item.status ? '有効' : '無効' }}
      </template>
      <template v-slot:item.actions='{ item }'>
        <v-btn color='error' outlined @click='edit(item)'>
          <v-icon small class='mr-2'>mdi-pencil</v-icon>
          編集
        </v-btn>
      </template>
    </v-data-table>
    <notification-dialog v-model="dialog" @reload="reload" :action="action" :selectNotification="selectNotification"></notification-dialog>
  </div>
</template>

<script>
import util from '../util';
import NotificationDialog from '../components/NotificationDialog';

export default {
  components: {
    NotificationDialog,
  },
  data: () => ({
    dialog: false,
    action: '',
    headers: [
      { text: '重要なお知らせ', align: 'start', sortable: false, value: 'important' },
      { text: 'お知らせタイトル', sortable: false, value: 'title' },
      { text: '表示開始', sortable: false, value: 'startDate' },
      { text: '表示終了', sortable: false, value: 'endDate' },
      { text: '掲載店舗', sortable: false, value: 'shops' },
      { text: '操作', sortable: false, value: 'actions' },
    ],
    notification: [],
    selectNotification: {},
    shops: [],
    loading: false,
  }),

  mounted() {
    this.reload();
  },

  methods: {
    async reload() {
      this.loading = true;
      this.shops = await util.callCloudFunction('getShop');
      this.notification = await util.callCloudFunction('getNotification');
      this.loading = false;
    },
    add() {
      this.action = 'add';
      this.selectNotification = {};
      this.dialog = true;
    },
    edit(item) {
      this.action = 'edit';
      this.selectNotification = item;
      this.dialog = true;
    },
    shopIdToShopName(ids) {
      const names = ids.map(id => this.shops.find((v) => v.id === id).name);
      return names.join(',  ');
    },
    toAbbreviate(data) {
      return `${data[0]} ~ ${data[data.length - 1]}`;
    },
  },
};
</script>
