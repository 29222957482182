<template>
  <v-menu
    ref="menu"
    v-model="menu"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="ma-2"
        outlined
        dense
        v-model="day"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="day"
      locale="ja-jp"
      :day-format="date => new Date(date).getDate()"
      no-title
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'Datepicker',
  props: ['selectDay'],
  data: () => ({
    menu: false,
  }),
  computed: {
    day: {
      get() {
        return this.selectDay;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
};
</script>
